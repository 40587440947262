<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { snakeToCamel, removeSpecialCharacters } from '@/support/utils/stringHelper'

export default {
  name: 'NotificationsSystemEmailsManage',

  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    InputField: () => import('@/components/general/InputField'),
    RichTextEditor: () => import('@/components/general/RichTextEditor'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    FormSection: () => import('@/components/general/FormSection'),
    SelectField: () => import('@/components/general/SelectField'),
    Radio: () => import('@/components/general/Radio')
  },

  data () {
    return {
      formData: {
        title: '',
        body: '',
        buttonAction: null,
        buttonText: null,
        buttonLink: null,
        textLink: null,
        active: false
      },

      VUE_APP_PORTAL_URL: process.env.VUE_APP_PORTAL_URL,

      richTextOptions: {
        formats: [
          'underline',
          'strike',
          'bold',
          'italic',
          'link',
          'color',
          'size',
          'align'
          // 'image',
          // 'header',
          // 'list',
          // 'blockquote',
          // 'indent'
        ],

        modules: {
          clipboard: {
            matchVisual: false
          }
        }
      },

      richTextToolbar: [
        [
          {
            size: [
              'small',
              false,
              'large'
            ]
          }
        ],
        [
          {
            color: [
              '#333438',
              '#6B6D71',
              '#ABC563'
            ]
          }
        ],
        [
          'bold',
          'italic',
          'underline',
          'strike'
        ],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        [ 'clean' ],
        [ 'link' ]
      ],

      isValidTitle: false,
      isValidBody: false,
      backUrl: { name: 'notifications.emails' },
      description: this.$t('notifications.edit:header.description'),
      name: this.$t('notifications.edit:header.description'),
      context: '',
      contextTagsTitle: {
        user: [
          {
            text: this.$t('notifications.systemEmails:tag.info.student'),
            value: 'student'
          }
        ],

        session: [
          {
            text: this.$t('notifications.systemEmails:tag.info.solution.title'),
            value: 'solutionTitle'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.session.name'),
            value: 'session'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.session.days'),
            value: 'sessionDays'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.student'),
            value: 'student'
          }
        ],

        solution: [
          {
            text: this.$t('notifications.systemEmails:tag.info.solution.title'),
            value: 'solutionTitle'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.student'),
            value: 'student'
          }
        ],

        survey: [
          {
            text: this.$t('notifications.systemEmails:tag.info.solution.title'),
            value: 'solutionTitle'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.student'),
            value: 'student'
          }
        ],

        learningPath: [
          {
            text: this.$t('notifications.systemEmails:tag.info.learningPath.title'),
            value: 'learningPathTitle'
          }
        ],

        financial: [
          {
            text: this.$t('notifications.systemEmails:tag.info.orderData.title'),
            value: 'orderData'
          }
        ],

        program: [
          {
            text: this.$t('notifications.systemEmails:tag.info.program.title'),
            value: 'programTitle'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.student'),
            value: 'student'
          }
        ]
      },

      contextTagsBody: {
        user: [
          {
            text: this.$t('notifications.systemEmails:tag.info.student'),
            value: 'student'
          }
        ],

        session: [
          {
            text: this.$t('notifications.systemEmails:tag.info.solution.title'),
            value: 'solutionTitle'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.workload'),
            value: 'workload'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.session.name'),
            value: 'session'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.session.initial.date'),
            value: 'sessionInitDate'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.session.initial.hour'),
            value: 'sessionInitHour'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.session.finish.date'),
            value: 'sessionFinishDate'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.session.finish.hour'),
            value: 'sessionFinishHour'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.session.days'),
            value: 'sessionDays'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.student'),
            value: 'student'
          // },
          // {
          //   text: this.$t('notifications.systemEmails:tag.info.assessments.pending'),
          //   value: 'pendingAssessments'
          // },
          // {
          //   text: this.$t('notifications.systemEmails:tag.info.assessments.totalLastWeek'),
          //   value: 'totalAssessmentsLastWeek'
          }
        ],

        solution: [
          {
            text: this.$t('notifications.systemEmails:tag.info.solution.title'),
            value: 'solutionTitle'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.student'),
            value: 'student'
          }
        ],

        survey: [
          {
            text: this.$t('notifications.systemEmails:tag.info.solution.title'),
            value: 'solutionTitle'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.student'),
            value: 'student'
          }
        ],

        learningPath: [
          {
            text: this.$t('notifications.systemEmails:tag.info.student'),
            value: 'student'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.learningPath.title'),
            value: 'learningPathTitle'
          }
        ],

        financial: [
          {
            text: this.$t('notifications.systemEmails:tag.info.student'),
            value: 'student'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.orderData.title'),
            value: 'orderData'
          }
        ],

        program: [
          {
            text: this.$t('notifications.systemEmails:tag.info.program.title'),
            value: 'programTitle'
          },
          {
            text: this.$t('notifications.systemEmails:tag.info.student'),
            value: 'student'
          }
        ]
      },

      buttonActionList: [
        {
          text: this.$t('notifications.systemEmails.buttonAction:enrollments'),
          value: 'enrollments'
        },
        {
          text: this.$t('notifications.systemEmails.buttonAction:solutions'),
          value: 'solutions'
        },
        {
          text: this.$t('notifications.systemEmails.buttonAction:myself'),
          value: 'myself'
        },
        // {
        //   text: this.$t('notifications.systemEmails.buttonAction:home'),
        //   value: 'home'
        // },
        // {
        //   text: this.$t('notifications.systemEmails.buttonAction:msTeams'),
        //   value: 'ms_teams'
        // },
        {
          text: this.$t('notifications.systemEmails.buttonAction:validateEmail'),
          value: 'validate_email'
        },
        {
          text: this.$t('notifications.systemEmails.buttonAction:survey'),
          value: 'survey'
        },
        {
          text: this.$t('notifications.systemEmails.buttonAction:loginUrl'),
          value: 'login_email'
        },
        // {
        //   text: this.$t('notifications.systemEmails.buttonAction:boletoUrl'),
        //   value: 'view_boleto'
        // },
        // {
        //   text: this.$t('notifications.systemEmails.buttonAction:myPurchasesUrl'),
        //   value: 'my_purchases'
        // },
        // {
        //   text: this.$t('notifications.systemEmails.buttonAction:adminAssessmentUrl'),
        //   value: 'admin_assessments'
        // },
        {
          text: this.$t('notifications.systemEmails.buttonAction:other'),
          value: 'other'
        }
      ],

      yesNoOptions: [
        {
          label: this.$t('global:yes'),
          value: true
        },
        {
          label: this.$t('global:no'),
          value: false
        }
      ]
    }
  },

  validations: {
    formData: {
      title: {
        required,
        systemEmailValidTitle: function () {
          return this.isValidTitle
        }
      },

      body: {
        required,
        systemEmailValidBody: function () {
          return this.isValidBody
        }
      },

      buttonText: { required },
      buttonAction: { required },
      buttonLink: {
        required: requiredIf(function () {
          return this.enableButtonLink
        })
      }
    }
  },

  computed: {
    ...mapGetters([ 'clientConfig' ]),

    enableButtonLink () {
      return this.formData.buttonAction === 'other'
    },

    enableTextLink () {
      return this.formData.textLink !== null
    }
  },

  watch: {
    'formData.buttonAction' (event) {
      if (event !== 'other') {
        this.formData.buttonLink = null
      }
    },

    'formData.title' () {
      this.formData.title = removeSpecialCharacters(this.formData.title)

      this.validateTitle()
    },

    'formData.body' () {
      this.validateBody()
    }
  },

  created () {
    this.setFetching(true)

    this.attemptManageSystemEmail({ idSystemEmail: this.$route.params.id }).then((response) => {
      this.parseSystemEmail(response)
    }).catch(() => {
      this.setFeedback({ message: this.$t('notifications.systemEmails.load:fetch.error') })
      this.$router.push(this.backUrl)
    }).finally(() => {
      this.setFetching(false)
    })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptManageSystemEmail',
      'attemptUpdateSystemEmail'
    ]),

    leave () {
      this.$router.push(this.backUrl)
    },

    parseSystemEmail (systemEmail) {
      this.formData.title = systemEmail.title
      this.formData.body = systemEmail.body
      this.formData.buttonAction = systemEmail.buttonAction
      this.formData.buttonText = systemEmail.buttonText
      this.formData.buttonLink = systemEmail.buttonLink
      this.formData.textLink = systemEmail.textLink
      this.formData.active = systemEmail.active
      this.context = systemEmail.context
      this.description = this.$t('notifications.systemEmails.list:' + snakeToCamel(systemEmail.type) + '.description')
      this.name = this.$t('notifications.systemEmails.list:' + snakeToCamel(systemEmail.type) + '.name')
    },

    submit () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.updateSystemEmail()

        return true
      }

      this.setFeedback({ message: this.$t('notifications.systemEmails:feedback.validation.error') })

      return false
    },

    updateSystemEmail () {
      this.setFetching(true)
      const formData = this.formatFormData()

      this.attemptUpdateSystemEmail({
        id: this.$route.params.id,
        data: formData
      }).then(() => {
        this.setFeedback({ message: this.$t('notifications.systemEmails:feedback.updated.success') })
      }).catch((response) => {
        this.setFeedback({ message: this.getParsedErrorMessage(response) })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    formatFormData () {
      const data = {}

      data.button_link = this.formData.buttonLink
      data.text_link = this.formData.textLink
      data.title = this.formData.title
      data.body = this.formatBody()
      data.button_text = this.formData.buttonText
      data.button_action = this.formData.buttonAction
      data.active = this.formData.active

      return data
    },

    formatBody () {
      let newBody = this.formData.body

      // newBody = newBody.replace(/<p class="ql-align-justify">/g, '<tr><td style="padding: 0 20px"><p style="line-height: 22px; color: #707070; font-size: 16px; font-family: Lato, Arial, sans-serif; text-align: justify;">')
      // newBody = newBody.replace(/<p class="ql-align-right">/g, '<tr><td style="padding: 0 20px"><p style="line-height: 22px; color: #707070; font-size: 16px; font-family: Lato, Arial, sans-serif; text-align: right;">')
      // newBody = newBody.replace(/<p class="ql-align-center">/g, '<tr><td style="padding: 0 20px"><p style="line-height: 22px; color: #707070; font-size: 16px; font-family: Lato, Arial, sans-serif; text-align: center;">')
      // newBody = newBody.replace(/<p>/g, '<tr><td style="padding: 0 20px"><p style="line-height: 22px; color: #707070; font-size: 16px; font-family: Lato, Arial, sans-serif">')
      // newBody = newBody.replace(/<p/g, '<tr><td style="padding: 0 20px"><p style="line-height: 22px; color: #707070; font-size: 16px; font-family: Lato, Arial, sans-serif"')
      newBody = newBody.replace(/<p/g, '<tr><td style="padding: 0 20px"><p')
      newBody = newBody.replace(/<\/p>/g, '</p></td></tr>')

      return newBody
    },

    addTagToBody (tag) {
      if (this.formData.body === null || this.formData.body === '') {
        this.formData.body = `<tr><td style="padding: 0 20px"><p style="font-family: Lato, Arial, sans-serif">{{${tag}}}</p>`
      } else {
        this.formData.body = this.formData.body.substring(0, this.formData.body.length - 4) + `{{${tag}}}</p>`
      }
    },

    addTagToTitle (tag) {
      this.formData.title = this.formData.title + `{{${tag}}}`
    },

    contextTags (section) {
      return this[section][this.context]
    },

    validateTitle () {
      const regex = new RegExp('({{.*?}})', 'g')
      const matches = this.formData.title.match(regex)
      const allowedTags = this.contextTags('contextTagsTitle')

      let validated = true
      const validatedTags = []

      if (matches) {
        for (const match of matches) {
          const filteredTags = allowedTags.filter((tag) => {
            if (typeof match === 'object') {
              return (match[0] === `{{${tag.value}}}`)
            } else {
              return (match === `{{${tag.value}}}`)
            }
          })

          if (filteredTags.length > 0) {
            validatedTags.push(filteredTags[0])
          }
        }
      }

      validated = matches ? validatedTags.length === matches.length : true
      this.isValidTitle = validated
    },

    validateBody () {
      const regex = new RegExp('({{.*?}})', 'g')
      const matches = this.formData.body.match(regex)
      const allowedTags = this.contextTags('contextTagsBody')

      let validated = true
      const validatedTags = []

      if (matches) {
        for (const match of matches) {
          const filteredTags = allowedTags.filter((tag) => {
            if (typeof match === 'object') {
              return (match[0] === `{{${tag.value}}}`)
            } else {
              return (match === `{{${tag.value}}}`)
            }
          })

          if (filteredTags.length > 0) {
            validatedTags.push(filteredTags[0])
          }
        }
      }

      validated = matches ? validatedTags.length === matches.length : true
      this.isValidBody = validated
    }
  }
}
</script>

<template>
  <div class="main-content notifications-edit">
    <ContentHeader
      :title="$t('notifications.edit:header.title')"
      dark-theme
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.notifications')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <ActionBar slot="actionbar" />
      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          flat
          dark
          @click="submit"
        />
      </template>
    </ContentHeader>
    <div class="main-content-inner">
      <div class="center">
        <div class="notifications-edit-header">
          <p
            class="notifications-edit-description"
            v-html="name"
          />
        </div>
        <form
          class="form"
          @submit.prevent="submit"
        >
          <FormSection :title="$t('notifications.edit:form.description')">
            <p v-html="description" />
          </FormSection>

          <FormSection :title="$t('notifications.edit:form.subject')">
            <InputField
              v-model="formData.title"
              :label="$t('notifications.edit:form.subject')"
              :validation="$v.formData.title"
            />
            <p class="tag-info">
              {{ $t('notifications.edit:form.management.tags.info.title') }}
            </p>
            <ul class="tags-list">
              <li
                v-for="(item, index) in contextTags('contextTagsTitle')"
                :key="index"
                class="tag-item"
                @click="addTagToTitle(item.value)"
              >
                <span class="tag-item-value">{{ item.text }}</span>
              </li>
            </ul>
          </FormSection>

          <FormSection :title="$t('notifications.edit:form.body')">
            <RichTextEditor
              v-model="formData.body"
              :toolbar="richTextToolbar"
              :options="richTextOptions"
              :label="$t('notifications.edit:form.body')"
              :under-description="`Atenciosamente, <br/>Equipe de Educação Digital ${clientConfig.clientName}<br><a href='${VUE_APP_PORTAL_URL}' target='_blank'>${VUE_APP_PORTAL_URL}</a>`"
              :validation="$v.formData.body"
            />
            <p class="tag-info">
              {{ $t('notifications.edit:form.management.tags.info.body') }}
            </p>
            <ul class="tags-list">
              <li
                v-for="(item, index) in contextTags('contextTagsBody')"
                :key="index"
                class="tag-item"
                @click="addTagToBody(item.value)"
              >
                <span class="tag-item-value">{{ item.text }}</span>
              </li>
            </ul>
          </FormSection>

          <FormSection :title="$t('notifications.systemEmails:form.button')">
            <InputField
              v-model="formData.buttonText"
              :label="$t('notifications.edit:form.button.text')"
              :validation="$v.formData.buttonText"
              :counter="50"
            />
            <SelectField
              v-model="formData.buttonAction"
              :tabindex="0"
              :label="$t('notifications.systemEmails:form.buttonAction')"
              :items="buttonActionList"
              :validation="$v.formData.buttonAction"
            />
            <InputField
              v-if="enableButtonLink"
              v-model="formData.buttonLink"
              :label="$t('notifications.edit:form.button.link')"
              :validation="$v.formData.buttonLink"
            />
          </FormSection>

          <FormSection
            v-show="enableTextLink"
            :title="$t('notifications.systemEmails:form.textLink')"
          >
            <Radio
              v-model="formData.textLink"
              :under-description="$t('notifications.systemEmails:form.textLink.description')"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.textLink"
            />
          </FormSection>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="../../../assets/scss/Notification.scss"></style>
